import React from 'react';
import PropTypes from 'prop-types';

const Spinner = (props) => {
  const s = require('./Spinner.module.scss');
  const { scale, isDark } = props;

  const darkClass = isDark ? ` ${s.dark}` : '';

  return (
    <div className={s.imageLoading}>
      <div className={s.circle + darkClass} style={{ width: scale, height: scale }} />
    </div>
  );
};

Spinner.propTypes = {
  scale: PropTypes.string
};

export default Spinner;
